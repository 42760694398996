<template>
  <button
    :class="{
      'primary-large-button': true,
      'primary-large-button-alert': !!alert,
    }"
    @click="onClick"
  >
    <div class="flex gap-2 justify-between items-center">
      <span
        v-if="icon != null"
        :class="{
          'icon text-[24px]': true,
          [icon]: true,
        }"
      >
      </span>
      <span v-if="label != null" class="l1">{{ label }}</span>
    </div>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    icon: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    onClick: {
      type: Function,
      required: false,
    },
    alert: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped>
.primary-large-button {
  @apply px-8 h-14 bg-primaryV2 text-surface-10 rounded-full cursor-pointer flex justify-center items-center;
}

.primary-large-button * {
  @apply text-surface-10;
}

.primary-large-button:hover {
  @apply bg-primary-60;
}

.primary-large-button:active {
  @apply bg-primary-60;
}

.primary-large-button:disabled {
  @apply bg-neutral-20 text-neutral-40 cursor-not-allowed;
}

.primary-large-button-alert {
  @apply bg-system-alert-base;
}

.primary-large-button-alert:hover {
  @apply bg-system-alert-60;
}
</style>
