<template>
  <AppBaseControlLayout
    direction="column"
    :class="class"
    :gap="gap"
    :containerType="containerType"
    :width="width"
    :shrink="shrink"
    :justify="justify"
    :color="color"
    :border="border"
    :items="items"
  >
    <slot></slot>
  </AppBaseControlLayout>
</template>

<script>
export default {
  name: "Column",
  props: {
    class: {
      type: String,
      required: false,
    },
    gap: {
      type: String,
      default: "small",
    },
    containerType: {
      type: String,
      default: "base",
    },
    width: {
      type: String,
      default: "auto",
    },
    justify: {
      type: String,
      default: "between",
    },
    shrink: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: "bg-surface-10",
    },
    border: {
      type: String,
      default: "",
    },
    items: {
      type: String,
      required: false,
    },
  },
};
</script>
