<template>
  <div class="transition-all duration-200" :class="containerClass">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "AppBaseControlLayout",
  props: {
    class: {
      type: String,
      required: false,
    },
    gap: {
      type: String,
      default: "small",
    },
    direction: {
      type: String,
      default: "column",
    },
    containerType: {
      type: String,
      default: "base",
    },
    width: {
      type: String,
      default: "auto",
    },
    justify: {
      type: String,
      default: "between",
    },
    shrink: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: "bg-surface-10",
    },
    border: {
      type: String,
      default: "",
    },
    items: {
      type: String,
      required: false,
    },
  },
  computed: {
    containerClass() {
      return {
        "w-auto": this.width == "auto",
        [`w-[${this.width}]`]: this.width != "auto",
        [`${this.color}`]: true,
        [`${this.border}`]: this.border.length > 0,
        flex: true,
        "flex-row": this.direction == "row",
        "flex-col": this.direction == "column",
        "flex-grow-0": this.shrink,
        "flex-grow": !this.shrink,
        [`justify-${this.justify}`]: true,
        "p-0": this.containerType == "base",
        "p-4": this.containerType == "background",
        "p-6": this.containerType == "core",
        "gap-0": this.gap == "zero",
        "gap-2": this.gap == "xsmall",
        "gap-3": this.gap == "small",
        "gap-4": this.gap == "medium",
        "gap-6": this.gap == "large",
        "gap-8": this.gap == "xlarge",
        "gap-10": this.gap == "xxlarge",
        [`items-${this.items}`]: !!this.items,
        [this.class]: !!this.class,
      };
    },
  },
};
</script>
